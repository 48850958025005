<template>
  <div class="checkout checkout__wrapper">
    <router-link
      v-if="show_title"
      :to="use_link"
      :event="admin_imported ? '' : 'click'"
    >
      <h1
        class="checkout__title"
        :style="{
          color: color_dark ? project_styles.colors.font_heavy : project_styles.colors.item_name_color
        }"
        :class="{
          'checkout__title--underlined-and-crop': inside_slider
        }"
        itemprop="name"
      >{{ get_correct_translation_value(product_data, "title", [correct_language]) }}</h1>
    </router-link>

    <p v-if="!product_variants_with_stock.length && !first_variant_button" class="checkout__out-of-stock">
      {{ translations.out_of_stock || $translate("products.out_of_stock") }}
    </p>

    <div
      v-if="!first_variant_button && calculate_percentage(get_correct_price_object.compare_at_price, get_correct_price_object.price) > 0"
      class="checkout__price"
      :class="{
        'checkout__price__item-slider': inside_slider && chosen_options
      }"
    >
      <span>
        <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        {{ price_including_tax_percentage }}
        <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
      </span>
      <span>
        <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        {{ compare_at_price_including_tax_percentage }}
        <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
      </span>
      <span class="checkout__price-discount">| {{ calculate_percentage(compare_at_price_including_tax_percentage, price_including_tax_percentage) }}%</span>

      <div v-if="Number(translations.tax_percantage || 0)" class="checkout__price-without-tax">
        <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        {{ get_correct_price_object.price }}
        <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        {{ translations.excluding_tax }}
      </div>
      <div v-else class="checkout__price-without-tax">{{ translations.including_tax }}</div>
    </div>
    <div
      v-else-if="!first_variant_button"
      class="checkout__price"
      itemprop="price"
      :class="{
        'checkout__price__item-slider': inside_slider && chosen_options
      }"
      :style="{
        color: color_dark ? project_styles.colors.font_heavy : project_styles.colors.item_name_color
      }"
    >
      <div data-cy="checkout-price">
        <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        {{ price_including_tax_percentage }}
        <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>

        <div v-if="Number(translations.tax_percantage || 0)" class="checkout__price-without-tax">
          <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          {{ get_correct_price_object.price }}
          <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          {{ translations.excluding_tax }}
        </div>
        <div v-else class="checkout__price-without-tax">{{ translations.including_tax }}</div>
      </div>
    </div>

    <!-------------- Product options ---------------->

    <div
      v-if="!first_variant_button && product_variants_with_stock.length && get_filtered_options.length"
      class="checkout__variants__wrapper"
      :class="{
        'checkout__variants__item-slider': inside_slider
      }"
    >
      <div
        v-if="!inside_slider"
        class="checkout__variant-alert"
        :class="{
          'checkout__variant-alert--show': alert_user_to_choose
        }"
      >
        {{ translations.select_variant_message || $translate("products.select_variant_message") }}
      </div>
      <div>
        <div
          v-for="({ values, name }, option_index) in get_filtered_options"
          :key="`product-option-${name}-${option_index}`"
          class="checkout__variants__variant"
          :class="{
            'checkout__variants__item-slider__wrapper': inside_slider,
            'checkout__variants__variant--no-padding': inside_slider
          }"
        >
          <span v-if="!inside_slider" class="checkout__variants__name">{{ name }}</span>
          <div
            v-for="({ value, can_be_selected }) in values"
            :key="`product-option-value-${name}-${value}`"
            class="checkout__variants"
            :style="{
              backgroundColor: is_value_a_color(value) ?
                value : project_styles.colors.background_heavy
            }"
            :class="{
              'checkout__variants--not-color': !is_value_a_color(value),
              'checkout__variants--is-chosen': check_if_chosen(name, value),
              'checkout__variants--cant-be-selected': !can_be_selected
            }"
            @click="can_be_selected && choose_option_value(value, name)"
          >
            {{ is_value_a_color(value) ? "" : value }}
          </div>
        </div>
      </div>
    </div>

    <!-------------- Amount adjuster input ---------------->

    <div v-if="product_variants_with_stock.length && !show_open_product_button && !first_variant_button" class="amount-adjuster">
      <input
        type="number"
        class="amount-adjuster__input"
        label="Chosen quantity input"
        aria-label="Chosen quantity input"
        v-model="chosen_quantity"
      >
      <div class="amount-adjuster__adjuster">
        <div @click="adjust_amount('+')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </div>
        <div @click="adjust_amount('-')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
          </svg>
        </div>
      </div>
    </div>

    <!-------------- Add to basket button ---------------->

    <div
      v-if="product_variants_with_stock.length && !first_variant_button"
      class="button button--green"
      data-cy="add-to-cart"
      :class="{
        'inline-block-element': !show_open_product_button,
        'button--red': inside_slider && alert_user_to_choose,
        'checkout__add-to-cart--grey': newly_added_to_cart && !is_marketplace_environment,
        'button--half-size': show_open_product_button,
        'marketplace-button': is_marketplace_environment,
        'marketplace-button--basic': !newly_added_to_cart && is_marketplace_environment,
        'marketplace-button--black-and-white': newly_added_to_cart && is_marketplace_environment
      }"
      @click="add_item_to_cart"
    >
      <div
        data-cy="add-to-cart"
        class="checkout__add-to-cart-animation"
        :class="{
          'checkout__add-to-cart-animation--show': adding_to_cart && !inside_slider,
          'checkout__add-to-cart-animation--animate': adding_to_cart && inside_slider
        }"
      >
        <img :src="find_correct_optimized_image" alt="Thumbnail image">
      </div>
      {{  admin_imported ? "Add to basket" : assemble_correct_basket_button_text }}
    </div>

    <!-- Contact button -->

    <div
      v-if="is_marketplace_environment"
      class="button marketplace-button marketplace-button--black-and-white checkout__contact-button"
      @click="() => show_contact_modal = !show_contact_modal"
    >
      {{ $translate("products.contact_seller") }}
      <envelope_icon />
    </div>

    <!------------ Go to product detail page button ------------>

    <router-link
      v-if="show_open_product_button"
      :to="product_link"
    >
      <div
        class="button button--green button--half-size checkout__product-link"
      >
        {{ translations.go_to_product || $translate("products.go_to_product") }}
      </div>
    </router-link>

    <!-------------- Go to basket ---------------->

    <router-link
      v-if="product_variants_with_stock.length && !inside_slider && newly_added_to_cart"
      to="/basket"
    >
      <div
        class="button button--green checkout__basket-link"
        :class="{ 'marketplace-button marketplace-button--basic': is_marketplace_environment }"
      >
        {{ translations.go_to_basket || $translate("products.go_to_basket") }}
      </div>
    </router-link>

    <!-- Product description -->

    <div
      v-if="add_description && message && !first_variant_button"
      class="checkout__message"
      v-html="message"
      itemprop="description"
    />

    <!-- Show buy now for first variant -->

    <div
      v-if="first_variant_button && !expand"
      :class="{
        'marketplace-button marketplace-button--basic checkout__buy-now': is_marketplace_environment,
      }"
      @click="() => { add_item_to_cart(); $router.push('/basket?load=false') }"
    >
      {{  admin_imported ? "Add to basket" : assemble_correct_basket_button_text }}
    </div>

    <!-- Contact modal -->

    <contact_modal
      :contact_email="product_data.project_email"
      :store_domain="product_data.project_domain"
      :show="show_contact_modal"
      :product_data="product_data"
      :selected_language="marketplace_selected_language"
      @close_modal="() => show_contact_modal = false"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import adjust_amount from "../../../Client/methods/adjust_amount"
import calculate_percentage from "../../../Client/methods/calculate_percentage"
import is_value_a_color from "../../methods/is_value_a_color"
import { SINGLE } from "../../../../data/product_variety_types"
import create_correct_product_url from "../../../Client/methods/create_correct_product_url"
import { default_option_name } from "../../../Admin/constants/others_constants"
import get_message from "../../methods/get_message_or_types_from_product_description"
import get_correct_price_object from "../../methods/get_correct_price_object"
import is_currency_symbol from "../../methods/is_currency_symbol"
import { CLIENT_STORE } from "../../../Client/constants/other"
import { currency_signs, SHARED_STORE } from "../../constants/other"
import { MARKETPLACE, MARKETPLACE_PRODUCTS_STORE } from "../../../Marketplace/constants/other"
import get_correct_translation_value from "../../methods/get_correct_translation_value"
import { ADD_BASKET_ITEM } from "../../stores/Shared/constants"
import { ga_event_actions } from "../../constants/ga_constants"
import { event_hub } from "../../../../main"
import get_correct_first_image from "../../methods/get_correct_first_image"
import get_image_src from "../../methods/get_image_src"
import round_to_two_decimals from "../../../Client/methods/round_to_two_decimals"
import { get_ga_instance } from "../../../../google_tracking"

const envelope_icon = () => import("../../../Shared/components/icon_components/envelope-icon")
const contact_modal = () => import("../../../Marketplace/components/utils/contact_modal")

export default {
  components: {
    envelope_icon,
    contact_modal
  },
  props: {
    product_data: {
      type: Object,
      required: true
    },
    add_description: {
      type: Boolean,
      required: false,
      default: true
    },
    color_dark: {
      type: Boolean,
      required: false,
      default: true
    },
    use_link: {
      type: String,
      required: false,
      default: ""
    },
    inside_slider: {
      type: Boolean,
      required: false,
      default: false
    },
    show_open_product_button: {
      type: Boolean,
      required: false,
      default: false
    },
    show_title: {
      type: Boolean,
      required: false,
      default: true
    },
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    first_variant_button: {
      type: Boolean,
      required: false,
      default: false
    },
    marketplace_selected_language: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      expand: false,
      alert_user_to_choose: false,
      adding_to_cart: false,
      newly_added_to_cart: false,
      show_contact_modal: false,
      chosen_options: [],
      chosen_quantity: 1,
      SINGLE
    };
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
    ]),
    ...mapState(CLIENT_STORE, [
      "products",
      "translations",
      "category_of_product_variety",
      "use_buy_it_now",
      "project_styles",
      "selected_language"
    ]),
    ...mapState([
      "is_marketplace_environment",
    ]),
    ...mapState(MARKETPLACE_PRODUCTS_STORE, {
      selected_product: "selected_product",
      marketplace_products: "products"
    }),
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    compare_at_price_including_tax_percentage() {
      let temp_price = this.get_correct_price_object.compare_at_price

      if (this.translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
    price_including_tax_percentage() {
      let temp_price = this.get_correct_price_object.price

      if (this.translations.tax_percantage) temp_price = temp_price * (
        1 + (Number(this.translations.tax_percantage) / 100)
      )

      return round_to_two_decimals(temp_price)
    },
    currency() {
      if (this.is_marketplace_environment) return ((
        this.product_data.translations || {}
      )[this.marketplace_selected_language] || {}).currency_code || this.marketplace_currency

      return this.translations.currency_code
    },
    currency_symbol() {
      return this.is_marketplace_environment ? currency_signs[this.currency] : (this.translations.currency_symbol || this.currency)
    },
    get_correct_products() {
      return this.is_marketplace_environment ? Array.from(new Set([
        ...Object.values(this.marketplace_products),
        ...(this.selected_product ? [this.selected_product] : [])
      ])) : this.products
    },
    product_variants_with_stock() {
      return this.product_data.variants.filter(({ inventory_quantity }) => inventory_quantity > 0)
    },
    quantity_number() {
      return Number(this.chosen_quantity)
    },
    correct_language() {
      return this.marketplace_selected_language || this.selected_language
    },
    get_product_options() {
      /**
       * Checks if first option is the default option for products
       * with only one option
       */
      return get_correct_translation_value(this.product_data.options[0], "values", [this.correct_language])?.[0] === default_option_name ?
        [] :
        this.product_variants_with_stock.reduce((options, variant) => {
          // Check for each possible option
          [1,2,3].forEach(option_number => {
            const option_value = get_correct_translation_value(variant, `option${option_number}`, [this.correct_language])

            // if the value of option isnt null
            if (option_value !== null && option_value !== undefined) {
              const correct_option_index = option_number - 1

              if (!this.product_data.options[correct_option_index]) return options

              const option_index_in_iteration = options.findIndex(option =>
                get_correct_translation_value(option, "name", [this.correct_language]) ===
                get_correct_translation_value(this.product_data.options[correct_option_index], "name", [this.correct_language])
              )

              if (
                option_index_in_iteration > -1 &&
                !options[option_index_in_iteration].values.includes(option_value)
              ) options[option_index_in_iteration].values.push(option_value)
              else if (option_index_in_iteration === -1) options.push({
                name: get_correct_translation_value(this.product_data.options[correct_option_index], "name", [this.correct_language]),
                values: [option_value]
              })
            }
          })

          return options
        }, [])
    },
    get_filtered_options() {
      return this.get_product_options.map(({ name, values }) => ({
        name: name,
        values: values.map(value => ({
          value,
          can_be_selected: this.check_if_chosen(name, value) || this.exists_variant_with_these_options(value)
        }))
      }))
    },
    get_correct_price_object() {
      return get_correct_price_object(this.chosen_options, this.product_data.variants, this.correct_language)
    },
    /**
     *
     */
    find_correct_optimized_image() {
      if (this.chosen_options.length) {
        const found_image = (this.product_data.images || []).find((image) => get_image_src(image).includes(this.chosen_options[0]))

        return found_image || get_correct_first_image(this.product_data)
      }

      return get_correct_first_image(this.product_data)
    },
    /**
     *
     */
    product_link() {
      return create_correct_product_url(this.product_data, this.is_marketplace_environment)
    },
    /**
     *
     */
    assemble_correct_basket_button_text() {
      if(this.inside_slider && this.alert_user_to_choose) return this.translations.select_variant_message

      return this.category_of_product_variety === SINGLE || this.use_buy_it_now || this.first_variant_button ?
        (this.translations.buy_now || this.$translate("products.buy_now")) : (this.translations.add_to_cart || this.$translate("products.add_to_basket"))
    },
    /*
     *
     */
    message() {
      return get_message(get_correct_translation_value(this.product_data, "body_html", [this.correct_language]), true)
    }
  },
  watch: {
    $route() {
      if(this.newly_added_to_cart) this.newly_added_to_cart = false

      this.chosen_options = []
    },
    chosen_options() {
      const image_id = this.get_variant()?.image_id

      this.$emit("user_changed_variation", { product_id: this.product_data.id, image_id })
      event_hub.$emit("user_changed_variation", { product_id: this.product_data.id, image_id })
    }
  },
  beforeDestroy() {
    this.newly_added_to_cart = false
  },
  methods: {
    ...mapMutations(SHARED_STORE, {
      add_basket_item: ADD_BASKET_ITEM
    }),
    adjust_amount,
    calculate_percentage,
    is_value_a_color,
    is_currency_symbol,
    get_correct_translation_value,
    check_if_chosen(name, value) {
      return this.chosen_options.some(
        ({ name: chosen_name, value: chosen_value }) => name === chosen_name && value === chosen_value
      )
    },
    exists_variant_with_these_options(option_value) {
      const selected_options = Array.from(new Set([...this.chosen_options.map(({ value }) => value), option_value]))

      // Check if the combination of the selected option values and incomming option value has an existing variant
      return this.product_variants_with_stock.some(variant =>
        selected_options.every(option_val => {
          const transformed_option_value = `/${option_val.replace(/\s/g, "")}/`

          return `/${get_correct_translation_value(variant, "title", [this.correct_language], true)}/`
            .replace(/\s/g, "")
            .includes(transformed_option_value)
        })
      )
    },
    find_variant_by_title(title, other_value) {
      return this.chosen_options.every(({ value }) => {
        const values_from_title = title.replace(/\s/g, "").split("/")

        return [
          value.replace(/\s/g, ""),
          ...(other_value ? [other_value] : [])
        ].every(val => values_from_title.find(title_val => val === title_val))
      })
    },
    choose_option_value(option_value, option_name) {
      if (!this.chosen_options.find(({ name }) => name === option_name))
        this.chosen_options.push({ name: option_name, value: option_value })
      else if (this.chosen_options.find(({ name, value }) => name === option_name && value === option_value))
        this.chosen_options = this.chosen_options.filter(({ name, value }) => name !== option_name && value !== option_value)
      else
        this.chosen_options = [
          ...this.chosen_options.filter(({ name }) => name !== option_name),
          { name: option_name, value: option_value }
        ]

      this.alert_user_to_choose = false
    },
    animate_adding_to_cart(el) {
      const element = el.target.querySelector(".checkout__add-to-cart-animation")
      const max_right_position = window.innerWidth
      this.adding_to_cart = true
      const is_user_using_mobile_view = this.is_mobile_device - 200 // 650px

      setTimeout(() => {
        const initial_element_state = element.getBoundingClientRect()
        const calculate_multiplication_difference = (window.innerWidth - initial_element_state.right) / initial_element_state.top
        const extra_multiplication_for_large_screens = calculate_multiplication_difference > 2.5 ? 2 : 1

        element.style.transition = "none"
        element.style.position = "fixed"
        element.style.top = initial_element_state.top + "px"
        element.style.left = initial_element_state.left + "px"

        const start_time = new Date().getTime();

        const animation_interval = setInterval(() => {
          element.style.top = Number(element.style.top.replace("px", "")) - (5 * extra_multiplication_for_large_screens)  + "px"
          element.style.left = Number(element.style.left.replace("px", "")) + (5 * calculate_multiplication_difference * extra_multiplication_for_large_screens) + "px"
          element.style.opacity = element.style.opacity === "" ? 1 : Number(element.style.opacity) - (is_user_using_mobile_view ? 0.02 : 0.05)

          // Check if element reached right hand corner or the timeout time hase finished
          if(
            (
              (element.getBoundingClientRect().top > 0 && element.getBoundingClientRect().top < 50) &&
              (element.getBoundingClientRect().right > max_right_position - 50 && element.getBoundingClientRect().right < max_right_position)
            ) ||
            new Date().getTime() - start_time > 2000
          ) {
            this.adding_to_cart = false
            event_hub.$emit("blink_basket_green")

            // Clear style data and interval
            element.style = ""
            clearInterval(animation_interval)
          }
        }, 5 * calculate_multiplication_difference)
      }, 500)
    },
    get_variant() {
      // Assigning ID of first variant because of logic described in get_product_options()
      if(this.product_data.variants.length === 1 || this.first_variant_button) return this.product_data.variants[0]

      return this.product_variants_with_stock.find(
        (variant) => this.find_variant_by_title(get_correct_translation_value(variant, "title", [this.correct_language], true))
      )
    },
    add_item_to_cart(el) {
      if (this.quantity_number >= 1 && !this.admin_imported) {
        if (this.chosen_options.length === this.get_product_options.length || this.first_variant_button) {
          const { category } = this.$route.params

          if(!this.inside_slider) this.newly_added_to_cart = true

          get_ga_instance().then((ga) => {
            ga.track(ga_event_actions.add_to_cart, {
              product_id: this.product_data.id,
              product_name: get_correct_translation_value(this.product_data, "title"),
              quantity: this.product_data.quantity,
              price: `${this.currency}-${this.get_correct_price_object.price}`
            })
          })

          // FB event
          if (window.fbq) fbq("track", "AddToCart", {
            value: this.get_correct_price_object.price,
            currency: this.currency,
            contents: {
              quantity:  this.product_data.quantity,
              id: this.product_data.id,
              price: this.get_correct_price_object.price,
            }
          });

          // Running add to cart animation
          if (this.inside_slider) {
            this.adding_to_cart = true
            setTimeout(() => {
              this.adding_to_cart = false
              event_hub.$emit("blink_basket_green")
            }, 1500)
          }
          else if (!this.is_marketplace_environment) this.animate_adding_to_cart(el)

          event_hub.$emit("adjust_cart", true);
          this.alert_user_to_choose = false;

          const variant = this.get_variant()

          this.add_basket_item([{
            ...this.product_data,
            title: get_correct_translation_value(this.product_data, "title", [this.correct_language]),
            price: this.get_correct_price_object.price,
            prices: this.product_data.translations ?
              Object
                .entries(this.product_data.translations)
                .reduce((tot, [key, { currency_code }]) => ({
                  ...tot,
                  ...((variant.translations && variant.translations[key]) && {
                    [key]: {
                      currency_code,
                      price: ((variant.translations || {})[key] || {}).price,
                      compare_at_price: ((variant.translations || {})[key] || {}).compareAtPrice,
                    }
                  })
                }), {}) : {},
            currency: this.currency,
            product_variant: variant,
            variant_id: variant.id,
            product_route: category ?
              this.$route.path :
              create_correct_product_url(
                this.get_correct_products.find(({ id }) => id === this.product_data.id),
                this.is_marketplace_environment
              ),
            quantity: this.quantity_number
          }, this.selected_language])

          if(this.category_of_product_variety === SINGLE || this.use_buy_it_now) this.$router.push("/basket")
        }
        else this.alert_user_to_choose = true;
      }
    }
  }
};
</script>

<style lang="scss">
@use "../../../../styles/amount_adjuster" as *;
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;
@use "../../../../styles/marketplace" as *;

$color-variant-size: 40px;

.checkout {
  &__product-link {
    width: calc(50% - 13px);
    margin-left: 8px !important;
  }

  &__basket-link {
    display: block;
    margin-top: 10px !important;
    animation: show-basket-link 1s ease;

    &.marketplace-button {
      margin: 5px 0 5px 5px !important;

      @media (max-width: $mobile) {
        display: block;
        margin-top: $spacing--tiny !important;
        text-align: center;
      }
    }
  }

  &__free-shipping {
    margin: 10px 0 !important;
    font-size: 12px;
    font-weight: bold;
  }

  &__contact-button {
    margin-left: 5px;
  }

  &__buy-now {
    margin-top: $spacing--tiny;
  }

  &__variants {
    display: inline-block;
    min-width: $color-variant-size;
    height: $color-variant-size;
    margin: 0 5px 5px 0;
    border-radius: 3px;
    cursor: pointer;
    vertical-align: middle;
    transition: 250ms ease-out;
    box-shadow: 0 0px 10px 0px $shadow-color;
    box-sizing: border-box;

    &__variant {
      position: relative;
      padding-top: 20px;

      &--no-padding {
        padding-top: 0;
      }
    }

    &__name {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
    }

    &__wrapper {
      position: relative;
      margin-bottom: 20px;
    }

    &--is-chosen {
      transform: scale(0.7);
    }

    &--cant-be-selected {
      opacity: 0.1;
      cursor: default;

      &:hover {
        background-color: initial;
      }
    }

    &--not-color {
      height: auto !important;
      padding: 10px;
      border: 1px solid var(--background_heavy);
      color: var(--item_name_color);
      text-align: center;
      transition: $default-transition;
      box-sizing: border-box;

      &.checkout__variants--is-chosen, &:hover {
        background-color: var(--item_name_color) !important;
        color: var(--background_heavy);
        width: auto;
        height: auto;
        box-shadow: none;
      }

      &.checkout__variants--cant-be-selected:hover {
        background-color: var(--background_heavy) !important;
        color: var(--item_name_color);
        width: auto;
        height: auto;
        box-shadow: 0 0px 10px 0px $shadow-color;
      }
    }

    &__item-slider {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
      }

      position: relative;
      max-height: 90px;
      max-width: 100%;
      overflow: auto;
      margin: 0 0 5px;

      &__wrapper {
        height: 25px;
        line-height: 25px;
        margin-bottom: 5px;
      }

      .checkout__variants {
        height: 25px;
        line-height: 25px;
        padding: 0 5px;
        font-size: 12px;
        margin: 0 5px 0 0;
        white-space: nowrap;
      }
    }
  }

  &__add-to-cart {
    &--grey {
      line-height: 36px;
      background-color: $grey;
      border: 1px solid $grey;
      color: $pure-white;

      &:hover {
        color: $grey;
        border: 1px solid $grey;
      }
    }

    &-animation {
      position: absolute;
      top: 0;
      left: calc(50% - 25px);
      width: 50px;
      height: 50px;
      z-index: -1;
      overflow: hidden;
      border-radius: 100%;
      background-color: $green-color;
      border: 1px solid $green-color;
      opacity: 0;
      transition: 250ms ease-out, opacity 0ms linear;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &--animate {
        top: -60px;
        z-index: 100;
        border-radius: 100%;
        opacity: 1;
        animation: animate-adding-to-cart 1.5s ease-out;
      }

      &--show {
        top: -60px;
        z-index: 100;
        opacity: 1;
        transition: 400ms ease-out;

        @media (max-width: $tablet--small) {
          top: -80px;
        }
      }
    }
  }

  &__out-of-stock {
    padding: 5px 10px;
    margin: 20px 0 0;
    background-color: $pure-white;
    color: var(--font_heavy);
    border: 1px solid var(--font_heavy);
  }

  &__wrapper {
    position: relative;
    max-width: 100%;
    min-width: 250px;
    margin: auto;
    text-align: left;
    color: var(--font_heavy);

    p {
      margin-left: 5px;
    }

    .inline-block-element {
      display: inline-block;
      width: calc(100% - 55px);
      vertical-align: top;
      margin: 0 0 0 5px;

      @media (max-width: $mobile) {
        width: calc(100% - 35px);
      }
    }

    .button {
      @media (max-width: $tablet) {
        height: 38px;
        line-height: 38px;
      }
    }
  }

  &__title {
    margin: 0 5px;
    font-size: 25px;
    letter-spacing: -0.5px;
    font-weight: bold;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &--underlined-and-crop {
      width: 100%;
      text-decoration: underline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: $tablet) {
      font-size: 20px;
    }

    @media (max-width: $tablet--small) {
      margin: 0;
      padding: 0;
    }
  }

  &__selector {
    position: relative;

    &--max-height {
      max-height: 90px;
      overflow: auto;
    }

    &__item {
      display: inline-block;
      min-width: 40px;
      padding: 2.5px 9px;
      margin: 0 5px 5px 0;
      line-height: 35px;
      text-align: left;
      background-color: var(--background_heavy);
      border: 1px solid var(--background_heavy);
      color: $pure-white;
      text-align: center;
      cursor: pointer;
      transition: $default-transition;
      box-sizing: border-box;

      &:hover {
        background-color: $pure-white;
        color: var(--background_heavy) !important;
      }

      @media (max-width: $tablet) {
        padding: 5.5px 14px;
      }

      &--selected {
        background-color: $pure-white;
        color: var(--font_heavy) !important;
        box-sizing: border-box;
      }

      &--with-categories {
        min-width: 35px;
        padding: 2.5px 6px;
        line-height: 28px;
      }
    }
  }

  &__variant-alert {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    line-height: 30px;
    color: $pure-white;
    background-color: $alert-color;
    opacity: 0;
    text-align: center;
    transition: $default-transition;
    z-index: -1;

    &--show {
      top: -40px;
      opacity: 1;
      z-index: 2;
    }

    &::after {
      position: absolute;
      top: calc(100% - 5px);
      left: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background-color: $alert-color;
      content: "";
      transform: rotate(45deg);
    }
  }

  &__message {
    color: var(--font_heavy);
    word-spacing: 2px;
    line-height: 20px;
    border-radius: 6px;
    margin-top: 20px;
    letter-spacing: -0.2px;
    line-height: 22px;

    h1, h2, h3, h4 {
      margin: 0 0 20px;
      padding: 0;
    }

    h3 {
      font-size: 20px !important;
    }

    h5, h6, p {
      margin: 0;
    }
  }

  &__price {
    margin: 5px 0 20px 5px;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 32px;

    &-without-tax {
      font-size: 14px;
      line-height: normal;
      opacity: 0.6;
    }

    &-discount {
      font-size: $font-size--medium;
      margin-left: 5px;
    }

    @media (max-width: $tablet) {
      font-size: 19px;
    }

    & > span {
      display: inline-block;
      vertical-align: middle;

      &:nth-child(2){
        font-size: 11px;
        color: var(--font_heavy);
        text-decoration: line-through;
      }

      &:last-child {
        font-size: 13px;
      }

      &:first-child {
        margin-right: 10px;
        color: $red-color;
      }
    }

    p {
      margin: 0;
    }
  }

  .amount-adjuster {
    $adjuster-height: 38px;
    height: $adjuster-height;

    &__adjuster {
      height: 100%;

      div {
        height: 50%;

        svg {
          margin-bottom: 1px;
        }
      }
    }
  }
}

@keyframes animate-adding-to-cart {
  0% {
    top: 0;
    z-index: -1;
    border-radius: 0;
    opacity: 0;
  }
  30% {
    top: -60px;
    z-index: 100;
    border-radius: 100%;
    opacity: 1;
  }
  70% {
    top: -60px;
    z-index: 100;
    border-radius: 100%;
    opacity: 1;
  }
  100% {
    top: -150px;
    border-radius: 100%;
    opacity: 0;
  }
}

@keyframes show-basket-link {
	0% {
		transform: translateY(-100%);
	}
	50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(-4%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(-2%);
	}
	100% {
		transform: translateY(0%);
	}
}
</style>
