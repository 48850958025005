var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkout checkout__wrapper"},[(_vm.show_title)?_c('router-link',{attrs:{"to":_vm.use_link,"event":_vm.admin_imported ? '' : 'click'}},[_c('h1',{staticClass:"checkout__title",class:{
        'checkout__title--underlined-and-crop': _vm.inside_slider
      },style:({
        color: _vm.color_dark ? _vm.project_styles.colors.font_heavy : _vm.project_styles.colors.item_name_color
      }),attrs:{"itemprop":"name"}},[_vm._v(_vm._s(_vm.get_correct_translation_value(_vm.product_data, "title", [_vm.correct_language])))])]):_vm._e(),(!_vm.product_variants_with_stock.length && !_vm.first_variant_button)?_c('p',{staticClass:"checkout__out-of-stock"},[_vm._v(" "+_vm._s(_vm.translations.out_of_stock || _vm.$translate("products.out_of_stock"))+" ")]):_vm._e(),(!_vm.first_variant_button && _vm.calculate_percentage(_vm.get_correct_price_object.compare_at_price, _vm.get_correct_price_object.price) > 0)?_c('div',{staticClass:"checkout__price",class:{
      'checkout__price__item-slider': _vm.inside_slider && _vm.chosen_options
    }},[_c('span',[(_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.price_including_tax_percentage)+" "),(!_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e()]),_c('span',[(_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.compare_at_price_including_tax_percentage)+" "),(!_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e()]),_c('span',{staticClass:"checkout__price-discount"},[_vm._v("| "+_vm._s(_vm.calculate_percentage(_vm.compare_at_price_including_tax_percentage, _vm.price_including_tax_percentage))+"%")]),(Number(_vm.translations.tax_percantage || 0))?_c('div',{staticClass:"checkout__price-without-tax"},[(_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.get_correct_price_object.price)+" "),(!_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.translations.excluding_tax)+" ")]):_c('div',{staticClass:"checkout__price-without-tax"},[_vm._v(_vm._s(_vm.translations.including_tax))])]):(!_vm.first_variant_button)?_c('div',{staticClass:"checkout__price",class:{
      'checkout__price__item-slider': _vm.inside_slider && _vm.chosen_options
    },style:({
      color: _vm.color_dark ? _vm.project_styles.colors.font_heavy : _vm.project_styles.colors.item_name_color
    }),attrs:{"itemprop":"price"}},[_c('div',{attrs:{"data-cy":"checkout-price"}},[(_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.price_including_tax_percentage)+" "),(!_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),(Number(_vm.translations.tax_percantage || 0))?_c('div',{staticClass:"checkout__price-without-tax"},[(_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.get_correct_price_object.price)+" "),(!_vm.is_currency_symbol(_vm.currency_symbol))?_c('span',[_vm._v(_vm._s(_vm.currency_symbol))]):_vm._e(),_vm._v(" "+_vm._s(_vm.translations.excluding_tax)+" ")]):_c('div',{staticClass:"checkout__price-without-tax"},[_vm._v(_vm._s(_vm.translations.including_tax))])])]):_vm._e(),(!_vm.first_variant_button && _vm.product_variants_with_stock.length && _vm.get_filtered_options.length)?_c('div',{staticClass:"checkout__variants__wrapper",class:{
      'checkout__variants__item-slider': _vm.inside_slider
    }},[(!_vm.inside_slider)?_c('div',{staticClass:"checkout__variant-alert",class:{
        'checkout__variant-alert--show': _vm.alert_user_to_choose
      }},[_vm._v(" "+_vm._s(_vm.translations.select_variant_message || _vm.$translate("products.select_variant_message"))+" ")]):_vm._e(),_c('div',_vm._l((_vm.get_filtered_options),function({ values, name },option_index){return _c('div',{key:`product-option-${name}-${option_index}`,staticClass:"checkout__variants__variant",class:{
          'checkout__variants__item-slider__wrapper': _vm.inside_slider,
          'checkout__variants__variant--no-padding': _vm.inside_slider
        }},[(!_vm.inside_slider)?_c('span',{staticClass:"checkout__variants__name"},[_vm._v(_vm._s(name))]):_vm._e(),_vm._l((values),function({ value, can_be_selected }){return _c('div',{key:`product-option-value-${name}-${value}`,staticClass:"checkout__variants",class:{
            'checkout__variants--not-color': !_vm.is_value_a_color(value),
            'checkout__variants--is-chosen': _vm.check_if_chosen(name, value),
            'checkout__variants--cant-be-selected': !can_be_selected
          },style:({
            backgroundColor: _vm.is_value_a_color(value) ?
              value : _vm.project_styles.colors.background_heavy
          }),on:{"click":function($event){can_be_selected && _vm.choose_option_value(value, name)}}},[_vm._v(" "+_vm._s(_vm.is_value_a_color(value) ? "" : value)+" ")])})],2)}),0)]):_vm._e(),(_vm.product_variants_with_stock.length && !_vm.show_open_product_button && !_vm.first_variant_button)?_c('div',{staticClass:"amount-adjuster"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.chosen_quantity),expression:"chosen_quantity"}],staticClass:"amount-adjuster__input",attrs:{"type":"number","label":"Chosen quantity input","aria-label":"Chosen quantity input"},domProps:{"value":(_vm.chosen_quantity)},on:{"input":function($event){if($event.target.composing)return;_vm.chosen_quantity=$event.target.value}}}),_c('div',{staticClass:"amount-adjuster__adjuster"},[_c('div',{on:{"click":function($event){return _vm.adjust_amount('+')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"}})])]),_c('div',{on:{"click":function($event){return _vm.adjust_amount('-')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"}})])])])]):_vm._e(),(_vm.product_variants_with_stock.length && !_vm.first_variant_button)?_c('div',{staticClass:"button button--green",class:{
      'inline-block-element': !_vm.show_open_product_button,
      'button--red': _vm.inside_slider && _vm.alert_user_to_choose,
      'checkout__add-to-cart--grey': _vm.newly_added_to_cart && !_vm.is_marketplace_environment,
      'button--half-size': _vm.show_open_product_button,
      'marketplace-button': _vm.is_marketplace_environment,
      'marketplace-button--basic': !_vm.newly_added_to_cart && _vm.is_marketplace_environment,
      'marketplace-button--black-and-white': _vm.newly_added_to_cart && _vm.is_marketplace_environment
    },attrs:{"data-cy":"add-to-cart"},on:{"click":_vm.add_item_to_cart}},[_c('div',{staticClass:"checkout__add-to-cart-animation",class:{
        'checkout__add-to-cart-animation--show': _vm.adding_to_cart && !_vm.inside_slider,
        'checkout__add-to-cart-animation--animate': _vm.adding_to_cart && _vm.inside_slider
      },attrs:{"data-cy":"add-to-cart"}},[_c('img',{attrs:{"src":_vm.find_correct_optimized_image,"alt":"Thumbnail image"}})]),_vm._v(" "+_vm._s(_vm.admin_imported ? "Add to basket" : _vm.assemble_correct_basket_button_text)+" ")]):_vm._e(),(_vm.is_marketplace_environment)?_c('div',{staticClass:"button marketplace-button marketplace-button--black-and-white checkout__contact-button",on:{"click":() => _vm.show_contact_modal = !_vm.show_contact_modal}},[_vm._v(" "+_vm._s(_vm.$translate("products.contact_seller"))+" "),_c('envelope_icon')],1):_vm._e(),(_vm.show_open_product_button)?_c('router-link',{attrs:{"to":_vm.product_link}},[_c('div',{staticClass:"button button--green button--half-size checkout__product-link"},[_vm._v(" "+_vm._s(_vm.translations.go_to_product || _vm.$translate("products.go_to_product"))+" ")])]):_vm._e(),(_vm.product_variants_with_stock.length && !_vm.inside_slider && _vm.newly_added_to_cart)?_c('router-link',{attrs:{"to":"/basket"}},[_c('div',{staticClass:"button button--green checkout__basket-link",class:{ 'marketplace-button marketplace-button--basic': _vm.is_marketplace_environment }},[_vm._v(" "+_vm._s(_vm.translations.go_to_basket || _vm.$translate("products.go_to_basket"))+" ")])]):_vm._e(),(_vm.add_description && _vm.message && !_vm.first_variant_button)?_c('div',{staticClass:"checkout__message",attrs:{"itemprop":"description"},domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e(),(_vm.first_variant_button && !_vm.expand)?_c('div',{class:{
      'marketplace-button marketplace-button--basic checkout__buy-now': _vm.is_marketplace_environment,
    },on:{"click":() => { _vm.add_item_to_cart(); _vm.$router.push('/basket?load=false') }}},[_vm._v(" "+_vm._s(_vm.admin_imported ? "Add to basket" : _vm.assemble_correct_basket_button_text)+" ")]):_vm._e(),_c('contact_modal',{attrs:{"contact_email":_vm.product_data.project_email,"store_domain":_vm.product_data.project_domain,"show":_vm.show_contact_modal,"product_data":_vm.product_data,"selected_language":_vm.marketplace_selected_language},on:{"close_modal":() => _vm.show_contact_modal = false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }